import React, { useEffect } from 'react';

import { Container, Row, Image, Button, Stack } from 'react-bootstrap';
import { FaLinkedinIn } from 'react-icons/fa';
import { BsGithub } from 'react-icons/bs';
import ReactGA from 'react-ga4';

import Header from './components/Header';
import SelectedWorkCard from './components/SelectedWorkCard';
import Contact from './components/Contact';

import ProfilePicture from './images/ProfilePicture.jpg';
import scrapeMatoes from './images/ScrapeMatoes.png';
import BudgetVisualizer from './images/BudgetVisualizer.png';
import CollegeTuitionAnalyzer from './images/CollegeTuitionAnalyzer.png';
import CuriosityApp from './images/CuriosityApp.png';

ReactGA.initialize('G-W4Q5H3J1MN');

const App = () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <>
      <Header />
      <Container fluid>
        <Row className="my-3 py-3 text-center">
          <h1 className="fw-bold mt-5"> Software Developer </h1>
          <h4 className="mt-3">
            {' '}
            I have a passion for solving complex problems through writing elegant code.{' '}
          </h4>
          <div className="mt-5">
            <Image src={ProfilePicture} roundedCircle style={{ width: '200px' }} />
          </div>
        </Row>

        <section id="about">
          <Row
            className="mt-5 py-5 justify-content-center text-center"
            style={{
              backgroundColor: '#663dff',
              backgroundImage: 'linear-gradient(319deg, #663dff 0%, #aa00ff 37%, #cc4499 100%)',
              color: 'white',
            }}>
            <div className="w-70" style={{ maxWidth: '800px' }}>
              <h2 className="fw-bold mt-3"> Hey, I'm Trung. Nice to meet you! </h2>
              <h5 className="mt-4">
                I'm a graduate from University of Claifornia, Santa Barbara majoring in Computer
                Science. I have worked on a variety of projects, ranging from full-stack web
                applications to mobile applications. I am a voracious learner, always looking for
                new opportunities to expand and refine my knowledge.
              </h5>
            </div>
          </Row>
        </section>

        <section id="selected-work">
          <Row className="mt-5 text-center">
            <h2 className="fw-bold"> Selected Work </h2>
            <h5 className="mt-2">Here are some of my recent projects.</h5>
          </Row>
          <SelectedWorkCard
            title="Curiosity App"
            description={
              <>
                A mobile application built to help users track their daily mood and activities. This
                application was built to help University of California, Santa Barbarbara Pyschology
                Department collect data for their research with around <strong> 80 </strong>{' '}
                participants. Built with <strong> Flutter </strong>
                and <strong> Firestore</strong> database and deployed on both Android and iOS.
              </>
            }
            image={CuriosityApp}
          />
          <SelectedWorkCard
            title="ScrapeMatoes"
            description={
              <>
                A web application data scraper that parses through a given Rotten Tomatoes movie
                link and extracts the relavant movie information. Performed on{' '}
                <strong>45,000 movie samples</strong> with a<strong> 72.95% success rate</strong>{' '}
                and utilized the extracted data to discover possible factors that contribute to a
                movie success.
              </>
            }
            image={scrapeMatoes}
            gitHubLink="https://github.com/trungbui2000/rotten-tomatoes-movRev-scrpr"
          />
          <SelectedWorkCard
            title="Budget Visualizer"
            description="
          A web application that assist users in visualizing their spendings through
          charting the inputted income and expenses. Provides multiple expense categories 
          to allow users to accurately track their spendings."
            image={BudgetVisualizer}
            gitHubLink="https://github.com/ucsb-cs48-s20/project-s0-t1-budget"
          />
          <SelectedWorkCard
            title="College Tuition Analyzer"
            description="
          An analysis on the different factors that influenced college tuition 
          within the U.S. colleges. The analysis leveraged available college admisison 
          data to visualize correlations. A model is then built to predict college tuitions."
            image={CollegeTuitionAnalyzer}
            gitHubLink="https://github.com/trungbui2000/college-tuition-diversity-analysis"
            pdfLink="https://drive.google.com/file/d/10hEmtyOFzHR4hVkp3LP_8UiWObOa-r9A/view?usp=drive_link"
          />
        </section>

        <section id="contact">
          <Row
            className="mt-5 py-3 py-md-4 justify-content-center"
            style={{
              backgroundColor: '#663dff',
              backgroundImage: 'linear-gradient(319deg, #663dff 0%, #aa00ff 37%, #cc4499 100%)',
              color: 'white',
            }}>
            <Contact />
          </Row>
        </section>

        <footer className="mt-5 mb-4 text-center">
          <Stack className="justify-content-center mb-2" direction="horizontal" gap={5}>
            <Button
              variant="outline-dark"
              role="link"
              size="lg"
              href="https://www.linkedin.com/in/findtrung">
              <FaLinkedinIn size="1.2em" />
            </Button>
            <Button
              variant="outline-dark"
              role="link"
              size="lg"
              href="https://github.com/trungbui2000">
              <BsGithub size="1.2em" />
            </Button>
          </Stack>

          <h6 className="fw-bold mt-5"> Made by me © 2025 Trung Bui </h6>
        </footer>
      </Container>
    </>
  );
};

export default App;
